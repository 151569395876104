import { defineStore } from 'pinia'
import { ref, watch } from "vue";
import { gql } from "graphql-tag";
import { useQuery, useSubscription } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@/composables/auth"
import { EventBus } from "@/events/eventBus";
import Swal from "sweetalert2";

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const loaded = ref(false);
    const route = useRoute();
    const router = useRouter();

    const auth = useAuth()

    const SELECT_USERS = gql`
        query Users {
            users_user{
                id
                email
                role
                available_credits
                last_updated
                websites(where: {parent_id: {_is_null: true}, deleted: { _eq: false}}, limit: 5000, order_by: { last_full_audit: desc } ){
                    id
                    shortuuid
                    url
                    last_full_audit
                }
                subscriptions{
                    id
                    state
                    canceled_at
                    paddle_cancel_url
                    paddle_update_billing_url
                    paddle_subscription_id
                    plan{
                        id
                        name
                    }
                }
                collaborators{
                    id
                    role
                    websites_website{
                        id
                        shortuuid
                        url
                    }
                }
            }
        }`

    const {result, onError, refetch} = useQuery(SELECT_USERS, null, () => ({
            enabled: route.path !== '/' && !route.meta.disableAuth
        })
    )
    watch(
        result,
        data => {
            loaded.value = true
            let plan = {
                id: "free",
                name: "free"
            }
            if (data.users_user[0].subscriptions.length > 0) {
                plan = {
                    id: data.users_user[0].subscriptions[0].plan.name,
                    canceledAt: data.users_user[0].subscriptions[0].canceled_at,
                    name: data.users_user[0].subscriptions[0].plan.name,
                    displayName: data.users_user[0].subscriptions[0].plan.name.charAt(0).toUpperCase() + data.users_user[0].subscriptions[0].plan.name.slice(1),
                }
            }
            user.value = {
                id: data.users_user[0].id,
                email: data.users_user[0].email,
                role: data.users_user[0].role,
                plan: plan,
                lastUpdated: data.users_user[0].last_updated,
                availableCredits: data.users_user[0].available_credits,
                websites: data.users_user[0].websites.map((website) => ({
                    id: website.id,
                    shortuuid: website.shortuuid,
                    url: website.url,
                    lastFullAudit: website.last_full_audit
                })),
                collaboratorSites: data.users_user[0].collaborators.map((collaborator) => ({
                    id: collaborator.websites_website.id,
                    role: collaborator.websites_website.role,
                    url: collaborator.websites_website.url
                }))
            }
            if (user.role === 'user') {
                selectUserSubscriptionActive.value.active = true;
            }
        },
        {
            lazy: true // Don't immediately execute handler
        }
    )

    onError(async (error) => {
        // navigate to login if not already there
        if (error.graphQLErrors[0].extensions.code === 'access-denied' && route.path !== '/login') {
            await auth.logout()
        }
    })

    const SELECT_USER_SUBSCRIPTION = gql`
        subscription Users{
            users_user{
                id
                last_updated
            }
        }`
    const selectUserSubscriptionActive = ref({
        active: false,
    })
    const {result: userSubscriptionResult} = useSubscription(SELECT_USER_SUBSCRIPTION, {}, () => ({
            enabled: selectUserSubscriptionActive.value,
        })
    )
    watch(
        userSubscriptionResult,
        async data => {
            if (refetch) {
                await refetch()
            }
            console.log('user refetch')
            EventBus.emit('refetchAll', null);
        },
    )

    function showNotification(type = 'success', message = '', title='') {
        const notification = {
            message: message,
            type: type,
            title: title,
        }
        EventBus.emit('showNotification', notification);
    }

    let toast = Swal.mixin({
        buttonsStyling: false,
        target: "#page-container",
        customClass: {
            confirmButton: "btn btn-success m-1",
            cancelButton: "btn btn-danger m-1",
            input: "form-control",
        },
    });

    function showUpgradeRequired() {
        toast
            .fire({
                title: "Upgrade your plan",
                text: "You need to upgrade your plan to access this feature",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-warning m-1",
                    cancelButton: "btn btn-secondary m-1",
                },
                confirmButtonText: "Go to billing",
                html: false,
            })
            .then(async (result) => {
                if (result.value) {
                    await router.push({name: 'subscription'})
                }
            });
    }

    return {loaded, user, refetch, showNotification, showUpgradeRequired}
})