<script setup>
import { useTemplateStore } from "@/stores/template";

const store = useTemplateStore();
</script>
<template>
  <footer id="page-footer" class="bg-body-light">
    <div class="content py-5">
      <div class="row fs-sm fw-medium justify-content-center text-center">
        <div class="col-sm-7 order-sm-1 py-1">
          <a class="fw-semibold" href="https://pagewatch.ai">{{
              store.app.name
            }}</a>
          &copy; {{ store.app.copyright }}

          <router-link :to="{name: 'privacy-policy'}" class="ms-4">Privacy Policy</router-link>
          <router-link :to="{name: 'tos'}" class="ms-4">Terms of Service</router-link>
          <router-link :to="{name: 'refunds'}" class="ms-4">Refunds</router-link>
            <a class="ms-4" href="mailto:hi@pagewatch.ai">Email</a>
            <a class="ms-4" target="_blank" href="https://x.com/pagewatchdev">Twitter</a>
        </div>

      </div>
    </div>
  </footer>
</template>
