<template>
  <div class="content">
    <div class="loading-container">

    <span class="spinner-border text-primary ms-2 me-2 text-muted"
          style="font-size:18px;height:28px;width:28px">      </span>

    </div>


  </div>
</template>
<script>
export default {
  name: 'loading-placeholder'
}
</script>
<style>

.table th:first-child,
.table td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  color: #373737;
  z-index: 5;
}

.loading-container {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  height: 80vh; /* use full viewport height */
}


</style>