<script setup>

import { useWebsiteStore } from "@/stores/website";
import { useUserStore } from "@/stores/user";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";


useActiveDeviceRenderStore()
const websiteStore = useWebsiteStore();
const userStore = useUserStore();

</script>

<template>
  <div v-if="!(websiteStore.loaded && userStore.loaded)">
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>
