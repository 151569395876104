<script setup>
import { useGrid, useScreen } from 'vue-screen'
import { useRoute, useRouter } from "vue-router";
import DevicePreview from "@/components/DevicePreview.vue";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useWebsiteStore } from "@/stores/website";
import AiSection from "@/views/preview/sections/AiSection.vue";
import PerformanceSection from "@/views/preview/sections/PerformanceSection.vue";
import IssueSection from "@/views/preview/sections/IssueSection.vue";
import linkifyHtml from "linkify-html";
import RadialProgress from "vue3-radial-progress";
import HistorySection from "@/views/preview/sections/HistorySection.vue";
import HighlightBox from "@/views/preview/partials/HighlightBox.vue";
import HttpStatusCodeBadge from "@/components/HttpStatusCodeBadge.vue";
import PerformanceIssue from "@/views/preview/partials/PerformanceIssue.vue";
import { useIssuesStore } from "@/stores/issues";

const websiteStore = useWebsiteStore();
const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
const screen = useScreen()
const grid = useGrid('bootstrap5')
const {scanStatus, lastUpdated, website} = storeToRefs(websiteStore);

const issuesStore = useIssuesStore();
const {
  brokenLinkMarks,
  crawlErrors,
  cloudFlareError,
  consoleWarnings,
  internalRedirectMarks,
  brokenImageMarks,
  missingAltTagImageMarks,
  brokenResources,
  inconsistentCapitalizationMarks,
  consoleErrors,
  layoutErrors,
  seoWarnings,
  performanceScore,
  spellingMarks,
  contentIssueMarks,
  contentErrors,
  seoErrors
} = storeToRefs(issuesStore);


function truncate(value, length = 36) {
  if (value.length >= length) {
    return value.substring(0, length) + '...'
  }
  return value

}

function replaceLink(deviceId) {
  let params = {...route.params};
  let query = {...route.query};
  params.deviceId = deviceId;
  // Go directly to audit instead of ai, hack to avoid reloading
  if (query.section === 'ai') {
    query = {}
  }
  router.push({name: route.name, params: params, query: query});
}

const devicePreview = ref(null);
const highlightBox = ref(null);
const highlightDetails = ref({
  content: {
    title: '',
    text_1: '',
    text_2: '',
    url: '',
  },
  style: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    'z-index': '1000',
    visibility: 'hidden',
  },
  index: 0,
  box: 0,
  nextLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  },
  prevLink: {
    query: {},
    show: false,
    style: {
      color: 'white',
      opacity: 0.8
    }
  }
})

function hideHighlightDetails() {
  highlightDetails.value.content = {
    title: '',
    text_1: '',
    text_2: '',
    context: '',
    showContext: false,
  }
  highlightDetails.value.style.visibility = 'hidden'
}


function showBoxes() {
  if (route.query.section) {
    return
  }
  if (!devicePreview.value) {
    return;
  }

  let issues = brokenLinkMarks.value
      .concat(internalRedirectMarks.value)
      .concat(brokenImageMarks.value)
      .concat(missingAltTagImageMarks.value)
      .concat(layoutErrors.value)
      .concat(spellingMarks.value)
      .concat(contentIssueMarks.value)
      .concat(inconsistentCapitalizationMarks.value);

  let highlightBoxes = []
  let oldHighlightBoxes = []
  let query = {}
  let ratio = devicePreview.value.ratio();

  let activeIssue = issuesStore.getIssueFromQuery(route.query).entry;

  let count = 0;
  for (let issue of issues) {

    let index = 0;
    for (let occurrence of issue.occurrences) {
      if (!occurrence) {
        highlightBoxes = []
        return
      }
      count += 1;
      index += 1;

      let box = {}
      Object.assign(box, occurrence.box);
      let q = {}
      Object.assign(q, query);
      q['i'] = index;
      q[issue.query] = issue.id;

      if (occurrence.id === 'text_overlap') {
        box.left = Math.min(occurrence.box.left, occurrence.box2.left);
        box.top = Math.min(occurrence.box.top, occurrence.box2.top);
        box.width = Math.max(occurrence.box.left + occurrence.box.width, occurrence.box2.left + occurrence.box2.width) - box.left;
        box.height = Math.max(occurrence.box.top + occurrence.box.height, occurrence.box2.top + occurrence.box2.height) - box.top;
      }
      if (box.width < 12) {
        box.left -= 6;
        box.top -= 6;
        box.width += 12;
        box.height += 12;
      }

      let variant = {
        'type': 'box',
        'color': 'red',
        'sentiment': 'negative',
      }
      if (occurrence.variant) {
        variant = occurrence.variant;
      }

      let id = `highlight-box-inactive-${count}`
      if (activeIssue && activeIssue.id === issue.id) {
        id = `highlight-box-${index}`
      }

      highlightBoxes.push({
        'id': id,
        'key': id,
        'i': `${index}`,
        'highlight': false,
        'priority': issue.priority,
        'active': activeIssue && activeIssue.id === issue.id,
        'sentiment': variant.sentiment,
        'box': box,
        'style': {
          'visibility': 'visible',
          'left': `${box.left * ratio}px`,
          'top': `${box.top * ratio}px`,
          'width': `${box.width * ratio}px`,
          'height': `${box.height * ratio}px`,
        },
        'query': q,
        'variant': variant
      })
    }
  }
  devicePreview.value.showBoxes(highlightBoxes)

}

function highlightEntry(entry) {
  let index = parseInt(route.query.i, 10) || 1;

  let occurrences = JSON.parse(JSON.stringify(entry.occurrences))
  let occurrence = entry.occurrences[index - 1];

  if (!occurrence) {
    return
  }

  let query = {}
  query[entry.query] = entry.id;
  occurrences.sort((a, b) => {
    if (a.box.top > b.box.top) {
      return 1
    }
    if (a.box.top < b.box.top) {
      return -1
    }
    return 0
  })
  highlightDetails.value.nextLink.query = {};
  highlightDetails.value.prevLink.query = {};
  Object.assign(highlightDetails.value.nextLink.query, query);
  Object.assign(highlightDetails.value.prevLink.query, query);
  highlightDetails.value.nextLink.query.i = index + 1
  highlightDetails.value.prevLink.query.i = index - 1
  highlightDetails.value.nextLink.show = true
  highlightDetails.value.prevLink.show = true
  if (index === 1) {
    highlightDetails.value.prevLink.show = false
  }
  if (index === occurrences.length) {
    highlightDetails.value.nextLink.show = false
  }

  highlightDetails.value.content = occurrence.highlightDetails;
  showBoxes()
  devicePreview.value.scrollToBox(occurrence.box)
}

function scrollToIssue() {

  hideHighlightDetails();
  // devicePreview.value.highlightEntries();
  let issue = issuesStore.getIssueFromQuery(route.query);
  if (issue.entry) {
    highlightEntry(issue.entry)
  } else {
    hideHighlightDetails();
    // devicePreview.value.highlightEntries();
  }
}

watch(() => route.query, (query) => {
  showBoxes()
  scrollToIssue()
})

function onLoaded(options) {
  showBoxes()
  scrollToIssue()
}

function scroll(dir) {
  hideHighlightDetails();
  devicePreview.value.scroll(dir)
}

function onManualScroll(scrollPosition) {
  hideHighlightDetails();
}

function onScrollToEntryCompleted() {
  let query = route.query;
  let index = parseInt(query.i, 10) || 0;
  if (highlightDetails.value.content.title) {
    highlightBox.value.showHighlightDetails()
  }
}

const maxWidth = computed(() => {
  if (grid.xl) {
    return 600;
  }
  if (grid.lg) {
    return 368;
  }
  return Math.min(screen.width - 100, 350)
})

const historyPreview = computed(() => {
  if (activeDeviceRender.historyData) {

    return {
      show: true,
      screenshotsNew: activeDeviceRender.historyData.screenshotsNew,
      screenshotsOld: activeDeviceRender.historyData.screenshotsOld,
    }
  }
  return {
    show: false,
    screenshotsNew: {},
    screenshotsOld: {},
  }
})

function logClick(event) {
  highlightBox.value.scrollFunction()
}

let progressColor = computed(() => {
  if (activeDeviceRender.page.score >= 80) {
    return 'green'
  }
  if (activeDeviceRender.page.score >= 50) {
    return 'orange'
  }
  return 'red'

})
</script>

<template>
  <highlight-box ref="highlightBox" :highlight-details="highlightDetails"></highlight-box>

  <div v-if="scanStatus.site.status === 'error'">

    <div class="container">
      <div class="row justify-content-end">
        <div class="col-12 p-4 mt-2">
          <div class="alert alert-danger" role="alert">
            <span class="fa fa-warning"></span> <b>Unable to run scan.</b>
            <p>
              {{ scanStatus.site.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="activeDeviceRender.loaded" class="container" @click="logClick">
    <div class="row justify-content-end">
      <div class="col-12 col-md-6 col-xl-7"
           v-show="['in_progress', 'completed'].includes(activeDeviceRender.scanState)">
        <div class="d-flex justify-content-center align-items-center"
             :class="`preview-${activeDeviceRender.devicePreview.deviceId}`">
          <table>
            <tbody>
            <tr>
              <td class="d-none d-lg-table-cell" style="width:30px">
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary mb-1 ms-3 ms-xl-0"
                    v-tooltip="`Scroll Up`"
                    @click="scroll('up')"
                >
                  <i class="fa fa-fw fa-up-long"/>
                </button>
                <br>
                <button
                    type="button"
                    class="btn btn-sm btn-alt-secondary mb-1  ms-3 ms-xl-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    v-tooltip="`Scroll Down`"
                    @click="scroll('down')"
                >
                  <i class="fa fa-fw fa-down-long"/>
                </button>
                <br>
                <a
                    type="button"
                    :href="activeDeviceRender.page.url"
                    target="_blank"
                    class="btn btn-sm btn-alt-secondary mt-1  ms-3 ms-xl-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    v-tooltip="`${activeDeviceRender.page.url}`"
                >
                  <i class="fa fa-link"/>
                </a>
              </td>

              <td class="text-center">
                <div style="position: relative;white-space: nowrap;min-width:220px;">
                  <device-preview ref="devicePreview" v-if="activeDeviceRender.loaded" :maxHeight="800"
                                  :maxWidth="maxWidth"
                                  :history="historyPreview"
                                  @loaded="onLoaded"
                                  :disableScreenshotMain="!!route.query.section"
                                  @on-manual-scroll="onManualScroll"
                                  @on-scroll-to-entry-completed="onScrollToEntryCompleted"
                                  v-bind="activeDeviceRender.devicePreview"></device-preview>
                  <br>

                </div>


              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="d-flex justify-content-center align-items-center pb-2">

          <span class="dropdown">
                  <button
                      type="button"
                      class="btn btn-sm btn-alt-primary dropdown-toggle me-2"
                      id="dropdown-default-alt-primary"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    {{ activeDeviceRender.devicePreview.deviceName }}
                  </button>
                  <div
                      class="dropdown-menu fs-sm"
                      aria-labelledby="dropdown-default-alt-primary"
                  >
                    <a class="dropdown-item" href="#here" @click.prevent="replaceLink(device.deviceId)"
                       v-for="device in activeDeviceRender.deviceRenders">{{ device.name }}</a>
                  </div>
                </span>
        </div>
      </div>
      <div
          class="col-12 col-md-6 col-xl-5 ps-4 pe-4 justify-content-end load-transition max-height-overflow"
          style="border-left: 1px solid #80808021;">

        <template v-if="route.query.section === 'ai'">
          <ai-section :feedback-category="`design_feedback`" :devicePreview="devicePreview"
                      v-if="devicePreview"></ai-section>
        </template>
        <template v-if="route.query.section === 'insights'">
          <ai-section :feedback-category="`first_impressions`" :devicePreview="devicePreview"
                      v-if="devicePreview"></ai-section>
        </template>
        <template v-if="route.query.section === 'performance'">
          <performance-section :devicePreview="devicePreview" v-if="devicePreview"></performance-section>

        </template>
        <template v-if="route.query.section === 'history'">
          <history-section :devicePreview="devicePreview" v-if="devicePreview"/>
        </template>
        <template v-if="!route.query.section">
          <template v-if="['in_progress', 'completed'].includes(activeDeviceRender.scanState)">
            <p></p>

            <div class="alert alert-danger" role="alert"
                 v-if="!activeDeviceRender.page.httpStatusOkOrPending">
              <p class="mb-0"><b>Page Load Error</b><br> We could not load this page, the server responded with a status
                code of {{ activeDeviceRender.page.httpStatusCode }}. </p>
            </div>
            <div v-else>

              <div class="background-block me-2 p-3 pb-4">
                <RadialProgress style="float:right"
                                :diameter="68"
                                innerStrokeColor="gray"
                                :startColor="progressColor"
                                :stopColor="progressColor"
                                :strokeWidth="5"
                                :innerStrokeWidth="4"
                                :completed-steps="activeDeviceRender.page.score"
                                :total-steps="100">
                  <span v-if="activeDeviceRender.page.score">{{ Math.round(activeDeviceRender.page.score) }}</span>
                  <div class="spinner-border" v-if="activeDeviceRender.page.score === 0"
                       style="font-size:2px;color:#2F495E;position:absolute;left:2px;top:6px;width:56px;height:56px;">
                    <span class="sr-only">Loading..</span>
                  </div>
                </RadialProgress>
                <div class="mb-1">Page Score</div>
                <small class="overflow-text-ellipsis text-muted pe-3 mt-2">{{
                    activeDeviceRender.page.title || '-'
                  }}</small>
              </div>
              <div v-if="['in_progress'].includes(activeDeviceRender.scanState)" class="alert alert-success mt-2 me-2"
                   role="alert">
                <div class="spinner-border"
                     style="font-size:11px;color:#2F495E;width:16px;height:16px;">
                  <span class="sr-only">Loading..</span>
                </div>
                Waiting for scan results..
              </div>

              <div v-if="cloudFlareError" class="alert alert-danger mt-2">
                <div><span class="fa fa-warning"></span> <b>We had some issues checking this page</b></div>
                <div class="ms-2 mt-1">Cloudflare is blocking our requests. Please see instructions on how to fix this
                  issue.
                </div>
              </div>
              <div class="pe-2"
                   v-if="spellingMarks.length || layoutErrors.filter(e=>e.priority === 'high').length || brokenLinkMarks.length || brokenImageMarks.length || seoErrors.length || contentErrors.length">
                <span class="badge text-bg-warning mb-2 mt-4">High Priority</span>
                <issue-section id="spelling" title="Spelling Issue" :issues="spellingMarks"></issue-section>

                <issue-section id="layout" title="Layout Error"
                               :issues="layoutErrors.filter(e=>e.priority === 'high')"></issue-section>

                <issue-section id="broken_link" title="Broken Link" :issues="brokenLinkMarks"></issue-section>

                <issue-section id="broken_image" title="Broken Image" :issues="brokenImageMarks"></issue-section>

                <issue-section id="content_error" title="Content Issue" :issues="contentErrors"></issue-section>


                <issue-section id="seo_issue" title="SEO Issue" :issues="seoErrors"></issue-section>


              </div>
              <div class="pe-2"
                   v-if="consoleErrors.length || layoutErrors.filter(e=>e.priority === 'medium').length  || brokenResources.length || (performanceScore > 0 && performanceScore < 50) || seoWarnings.length || contentIssueMarks.length || missingAltTagImageMarks.length">

                <span class="badge  mb-2 mt-4" style="background-color:orange">Medium Priority</span>

                <issue-section id="layout" priority="medium" title="Layout Issue"
                               :issues="layoutErrors.filter(e=>e.priority === 'medium')"></issue-section>
                <issue-section id="content" priority="medium" title="Content Issue"
                               :issues="contentIssueMarks"></issue-section>


                <performance-issue v-if="(performanceScore > 0 && performanceScore < 50)"/>

                <issue-section id="missing_alt_tag" title="Missing Alt Tag" :issues="missingAltTagImageMarks"></issue-section>


                <issue-section id="seo_warning" title="SEO Issue" :issues="seoWarnings"></issue-section>

                <issue-section id="console_errors" title="Error" :issues="consoleErrors"></issue-section>
                <issue-section id="broken_resources" title="Broken Resource" :issues="brokenResources"></issue-section>

              </div>
              <div class="pe-2"
                   v-if="internalRedirectMarks.length || inconsistentCapitalizationMarks.length || (performanceScore > 50 && performanceScore < 80) || consoleWarnings.length > 0">

                <span class="badge  mb-2 mt-4" style="background-color:gray">Low Impact</span>

                <issue-section id="internal_redirect" title="Internal Redirect"
                               priority="medium" :issues="internalRedirectMarks"></issue-section>

                <issue-section id="inconsistent_capitalization" title="Capitalization Issue"
                               priority="low" :issues="inconsistentCapitalizationMarks"></issue-section>

                <performance-issue v-if="(performanceScore > 50 && performanceScore < 80)"/>

                <issue-section id="console_warnings" title="Warning" :issues="consoleWarnings"></issue-section>

              </div>
              <div
                  v-if="['completed'].includes(activeDeviceRender.scanState) && !(spellingMarks.length || layoutErrors.length || brokenLinkMarks.length || brokenImageMarks.length ||
                  internalRedirectMarks.length || inconsistentCapitalizationMarks.length || consoleErrors.length ||
                  brokenResources.length || seoErrors.length || seoWarnings.length || performanceScore < 80 || consoleWarnings.length || contentIssueMarks.length)">
                <p></p>
                <div class="alert alert-success" role="alert">
                  <span class="fa fa-thumbs-up"></span> No Issues Found
                </div>
              </div>
            </div>
          </template>
          <p></p>
        </template>
      </div>

    </div>

  </div>


</template>
<style>

.preview-chrome_1366 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.preview-chrome_1280 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.infoblock .block-content {
  padding: 4px;
  font-size: 94%;
}


.issue-alert {
  background-color: red;
  z-index: 99;
  max-width: 450px;
  white-space: normal;
  color: white;
  padding: 4px;
  border-radius: 8px;
  font-size: 13px;
  span.fa-caret-down {
    color:red;
  }
}

.issue-alert .info {
  color: #1391aa;
  span.fa-caret-down {
    color:#1391aa;
  }
}

.issue-alert .danger {
  color: red;
  span.fa-caret-down {
    color:red;
  }
}

.issue-alert.info {
  background-color: #1391aa;
  span.fa-caret-down {
    color:#1391aa;
  }
}

.issue-alert-thin {
  max-width: 380px;
}

.issue-alert a {
  color: white;
  text-decoration: underline;
}

.issue-alert-code {
  margin-bottom: 0;
  overflow-wrap: break-word;
  margin-top: 4px;
  background-color: #464646;
  font-size: 11px;
  max-height: 54px;
}
</style>