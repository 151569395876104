import { defineStore } from 'pinia'
import { computed, ref, watch } from "vue";
import { gql } from "graphql-tag";
import { useQuery, useSubscription } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@/composables/auth"
import { EventBus } from "@/events/eventBus";
import { useWebsiteStore } from "@/stores/website";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import linkifyHtml from "linkify-html";

export const useIssuesStore = defineStore('issues', () => {
    const activeDeviceRender = useActiveDeviceRenderStore();
    const issues = ref({'name': 'hello'});
    const route = useRoute();
    const router = useRouter();

    function truncate(value, length = 36) {
        if (value.length >= length) {
            return value.substring(0, length) + '...'
        }
        return value

    }

    function processOccurrences(issueType, callbackFunction = null) {
        let result = []
        for (let deviceRender of activeDeviceRender.deviceRenders) {
            if (deviceRender[issueType]) {
                for (let entry of deviceRender[issueType]) {
                    let existing = result.find((item) => {
                        return item.id === entry.id
                    });

                    if (existing) {
                        if (!existing['devices'].map(d => d.name).includes(deviceRender.name)) {
                            existing['devices'].push({
                                name: deviceRender.name,
                                renderId: deviceRender.id,
                                occurrences: entry.occurrences,
                                id: deviceRender.deviceId,
                            })
                        }
                        if (deviceRender.deviceId === activeDeviceRender.deviceRender.deviceId) {
                            existing.occurrences = JSON.parse(JSON.stringify(entry.occurrences));
                            existing.priority = 'high';
                            if (callbackFunction) {
                                callbackFunction(entry, existing);
                            }
                        }
                    } else {
                        let box = {}
                        Object.assign(box, entry.occurrences[0].box);
                        let newItem = {
                            id: entry.id,
                            reason: entry.reason,
                            occurrences: deviceRender.deviceId === activeDeviceRender.deviceRender.deviceId ? entry.occurrences : [],
                            devices: [{
                                name: deviceRender.name,
                                renderId: deviceRender.id,
                                occurrences: entry.occurrences,
                                id: deviceRender.deviceId,
                            }]
                        }
                        newItem = JSON.parse(JSON.stringify(newItem))

                        if (callbackFunction) {
                            callbackFunction(entry, newItem);
                        }

                        result.push(newItem)
                    }
                }
            }
        }
        return result
    }

    const inconsistentCapitalizationMarks = computed(() => {

        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        return processOccurrences('inconsistentCapitalizationMarks', function (entry, newItem) {
            newItem.word = entry.word;
            newItem.suggestion = entry.suggestion;
            newItem.query = 'inconsistent_capitalization';
            newItem.priority = entry.priority || 'low';

            newItem.name = `${entry.word} --> ${entry.suggestion}`;
            newItem.description = ''
            newItem.ignoreButton = {
                show: true,
            };

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}
                occurrence.highlightDetails.title = `Inconsistent Capitalization: ${entry.word}`;
                occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
                occurrence.highlightDetails.context = occurrence.context;
                occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }
            }

        });

    })

    const brokenLinkMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let entries = processOccurrences('brokenLinkMarks', function (entry, newItem) {
            newItem.query = 'broken_link';

            newItem.priority = entry.priority || 'high';
            newItem.showStatusCode = true;
            newItem.statusCode = `${entry.status}`;
            newItem.name = `${entry.occurrences[0].text}`;
            newItem.description = entry.url;

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                occurrence.highlightDetails.title = `Broken Link Detected`;
                occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
                    defaultProtocol: 'https',
                    className: 'external-link-plain',
                    target: {
                        url: '_blank'
                    }
                });
                occurrence.highlightDetails.context = occurrence.context;
                occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }

        });
        entries.sort((a, b) => {
            if (!a.occurrences[0] || !b.occurrences[0]) {
                return 0;
            }
            // First, sort by 'visible' (true values first)
            let occurrencesA = a.occurrences[0];
            let occurrencesB = b.occurrences[0];

            if (occurrencesA.visible && !occurrencesB.visible) return -1;
            if (!occurrencesA.visible && occurrencesB.visible) return 1;

            if (occurrencesA.box.top < occurrencesB.box.top) return -1;
            if (occurrencesA.box.top > occurrencesB.box.top) return 1;

            if (occurrencesA.box.left < occurrencesB.box.left) return -1;
            if (occurrencesA.box.left > occurrencesB.box.left) return 1;

            return 0; // If all criteria are equal
        });
        return entries

    })

    const spellingMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let entries = processOccurrences('spellingMarks', function (entry, newItem) {
            newItem.word = entry.word;
            newItem.suggestion = entry.suggestion;
            newItem.query = 'spelling';
            newItem.priority = entry.priority || 'high';
            newItem.ignoreButton = {
                show: true,
            };

            newItem.name = `${entry.word} --> ${entry.suggestion}`;
            newItem.description = ''

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                if (entry.word.toLowerCase() === entry.suggestion.toLowerCase()) {
                    occurrence.highlightDetails.title = `Inconsistent Capitalization: ${entry.word}`;
                    occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
                    occurrence.highlightDetails.context = occurrence.context;
                    occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.context_type));
                } else {
                    if (['title', 'alt'].includes(occurrence.context_type)) {
                        occurrence.highlightDetails.title = `Possible spelling error in ${occurrence.context_type} text: ${entry.word}`;
                    } else {
                        occurrence.highlightDetails.title = `Possible spelling error: ${entry.word}`;
                    }
                    occurrence.highlightDetails.text_1 = `Suggestion: ${entry.suggestion}`;
                    occurrence.highlightDetails.context = occurrence.context;
                    occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.context_type));
                }

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }

            newItem.occurrences.sort((a, b) => {
                if (a.visible && !b.visible) return -1;
                if (!a.visible && b.visible) return 1;
                if (a.box.top < b.box.top) return -1;
                if (a.box.top > b.box.top) return 1;
                if (a.box.left < b.box.left) return -1;
                if (a.box.left > b.box.left) return 1;
                return 0;
            });

        });
        entries.sort((a, b) => {
            if (!a.occurrences[0] || !b.occurrences[0]) {
                return 0;
            }
            // First, sort by 'visible' (true values first)
            let occurrencesA = a.occurrences[0];
            let occurrencesB = b.occurrences[0];

            if (occurrencesA.visible && !occurrencesB.visible) return -1;
            if (!occurrencesA.visible && occurrencesB.visible) return 1;

            if (occurrencesA.box.top < occurrencesB.box.top) return -1;
            if (occurrencesA.box.top > occurrencesB.box.top) return 1;

            if (occurrencesA.box.left < occurrencesB.box.left) return -1;
            if (occurrencesA.box.left > occurrencesB.box.left) return 1;

            return 0; // If all criteria are equal
        });
        return entries;

    })
    const contentIssueMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        return processOccurrences('contentIssueMarks', function (entry, newItem) {
            newItem.reason = entry.reason;
            newItem.query = 'content';
            newItem.priority = entry.priority || 'low';

            newItem.name = `${entry.reason}`;
            newItem.description = ''

            if (entry.reason === 'copyright') {
                newItem.name = `Outdated Copyright Notice (${entry.expire_year})`;
            }

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}
                occurrence.highlightDetails.title = `Outdated Copyright Notice (${entry.expire_year})`;
                occurrence.highlightDetails.text_1 = `The copyright notice should be updated to the current year`;

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }
            }

        });

    })

    const seoErrors = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let result = []

        if (activeDeviceRender.page.seoIssueMissingTitle) {
            result.push({
                id: 'seo_issue_missing_title',
                name: 'Missing Document Title',
                description: 'Page titles are crucial for usability and SEO.',
                occurrences: [],
                devices: []
            })
        }

        return result

    })

    const contentErrors = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let result = []
        if (activeDeviceRender.page.contentIssueMissingFavicon) {
            result.push({
                id: 'content_issue_missing_favicon',
                name: 'Missing Favicon',
                description: 'Favicon images are important for branding and usability.',
                occurrences: [],
                devices: []
            })
        }

        return result

    })
    const seoWarnings = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let result = []

        if (activeDeviceRender.page.seoIssueMissingDescription) {
            result.push({
                id: 'seo_issue_missing_description',
                name: 'Missing Meta Description',
                description: 'Meta descriptions are important for usability and SEO.',
                occurrences: [],
                devices: []
            })
        }


        return result

    })
    const layoutErrors = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        return processOccurrences('layoutErrors', function (entry, newItem) {

            newItem.query = 'layout';
            newItem.priority = entry.priority || 'high';

            let errorName = entry.reason;
            let reason = entry.reason;
            let description = '';
            if (reason === 'horizontal_scrolling') {
                errorName = 'Horizontal Scrolling Detected'
                description = 'Unless intended pages should not scroll in two dimensions. '
            }
            if (reason === 'text_overlap') {
                errorName = 'Overlapping Text Detected'
                description = 'Overlapping text can cause severe readability issues.'
            }
            if (reason === 'entity') {
                errorName = 'Character Escaping Error'
                description = `"${entry.occurrences[0].word}" was double escaped, did you mean "${entry.occurrences[0].suggestion}".`
            }
            if (['overflow_w', 'overflow_h'].includes(reason)) {
                errorName = 'Overflowing Container'
                description = `"${entry.occurrences[0].text}" is overflowing its container.`
            }
            if (reason === 'lsep') {
                errorName = 'LSEP character (invisible line break) detected'
                description = 'On certain browsers this will display as a box (☐) or cause spacing issues between words.'
                newItem.priority = 'medium';
            }
            if (reason === 'etx') {
                errorName = 'ETX character ("End of Text") detected'
                description = 'On certain browsers this will display as a box (☐) or cause spacing issues between words.'
                newItem.priority = 'medium';
            }
            if (reason === 'aspect_ratio') {
                errorName = 'Incorrect Aspect Ratio'
                description = 'Image displayed with incorrect aspect ratio.'
            }
            if (reason === 'unsupported_unicode') {
                errorName = 'Unsupported Unicode Character'
                description = 'These might display as a box (☐) or similar depending on the browser.'
            }
            if (reason === 'narrow') {
                errorName = 'Narrow Vertical Text'
                description = 'Very tall and narrow text usually is caused by a missing breakpoint.'
            }
            if (reason === 'outside_window') {
                errorName = 'Text flows outside of window'
                description = entry.occurrences[0].text

            }
            if (reason === 'button_overlap') {
                errorName = 'Overlapping Buttons Detected'
                description = `The button "${truncate(entry.occurrences[0].text, 35)}" overlaps with another button`
            }
            newItem.name = errorName;
            newItem.description = description;
            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                occurrence.highlightDetails.title = errorName;
                occurrence.highlightDetails.text_1 = description;
                if (reason === 'horizontal_scrolling') {
                    occurrence.highlightDetails.text_2 = 'The element below is the most likely cause.'
                }
                if (reason === 'aspect_ratio') {
                    occurrence.highlightDetails.text_2 = `<a title="External Link" target="_blank" href="${occurrence.url}" class="external-link overflow-text-ellipsis-inline">${occurrence.url}</a>`;
                }
                occurrence.highlightDetails.showContext = false;


                if (['outside_window', 'unsupported_unicode'].includes(reason)) {
                    occurrence.box.width += 4;
                    occurrence.box.height += 4;
                    occurrence.box.left -= 2;
                    occurrence.box.top -= 2;
                }
                if (['button_overlap', 'text_overlap'].includes(reason)) {
                    occurrence.box.top = Math.min(occurrence.box.top, occurrence.box2.top)
                    occurrence.box.left = Math.min(occurrence.box.left, occurrence.box2.left)
                    occurrence.box.height = Math.max(occurrence.box.top + occurrence.box.height, occurrence.box2.top + occurrence.box2.height) - occurrence.box.top
                    occurrence.box.width = Math.max(occurrence.box.left + occurrence.box.width, occurrence.box2.left + occurrence.box2.width) - occurrence.box.left
                }
                if ((['horizontal_scrolling'].includes(reason) || occurrence.highlightDetails.showContext) && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }

        });

    })

    const consoleErrors = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let results = []
        for (let consoleLog of activeDeviceRender.page.consoleLogIssues) {
            if (!['error', 'pageerror'].includes(consoleLog.type)) {
                continue;
            }
            results.push({
                name: 'console_error',
                classes: ['text-danger', 'bg-danger-subtle', 'rounded', 'issue-item'],
                text: consoleLog.stack || consoleLog.text,
                occurrences: consoleLog.occurrences,
                type: consoleLog.type,
            })
        }
        return results;
    })

    const consoleWarnings = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let results = []
        for (let consoleLog of activeDeviceRender.page.consoleLogIssues) {
            if (!['warning'].includes(consoleLog.type)) {
                continue;
            }
            results.push({
                name: 'console_warning',
                classes: ['text-black', 'bg-warning-subtle', 'rounded', 'issue-item'],
                text: consoleLog.stack || consoleLog.text,
                occurrences: consoleLog.occurrences,
                type: consoleLog.type,
            })
        }
        return results;
    })

    const brokenResources = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let results = []
        for (let brokenResource of activeDeviceRender.page.brokenResourceIssues) {
            results.push({
                name: 'broken_resource',
                classes: ['text-danger', 'bg-warning-subtle', 'rounded', 'overflow-text-ellipsis', 'issue-item'],
                url: brokenResource.url,
                status: brokenResource.status,
                type: brokenResource.type,
            })
        }
        return results;
    })

    const internalRedirectMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let entries = processOccurrences('internalRedirectMarks', function (entry, newItem) {
            newItem.query = 'internal_redirect';
            newItem.priority = entry.priority || 'low';

            newItem.name = `${entry.url}`;
            newItem.description = `Redirected to: ${entry.redirected_to}`;

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                occurrence.highlightDetails.title = `Internal Redirect Detected`;
                occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
                    defaultProtocol: 'https',
                    className: 'external-link',
                    target: {
                        url: '_blank'
                    }
                });
                occurrence.highlightDetails.text_2 = 'Redirected to: ' + linkifyHtml(entry.redirected_to, {
                    defaultProtocol: 'https',
                    className: 'external-link',
                    target: {
                        url: '_blank'
                    }
                });
                occurrence.highlightDetails.context = occurrence.context;
                occurrence.highlightDetails.showContext = !occurrence.visible || (['title', 'alt'].includes(occurrence.contextType));

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }


        });

        entries.sort((a, b) => {
            if (!a.occurrences[0] || !b.occurrences[0]) {
                return 0;
            }
            // First, sort by 'visible' (true values first)
            let occurrencesA = a.occurrences[0];
            let occurrencesB = b.occurrences[0];

            if (!(occurrencesA && occurrencesB)) {
                return 0;
            }

            if (occurrencesA.visible && !occurrencesB.visible) return -1;
            if (!occurrencesA.visible && occurrencesB.visible) return 1;

            if (occurrencesA.box.top < occurrencesB.box.top) return -1;
            if (occurrencesA.box.top > occurrencesB.box.top) return 1;

            if (occurrencesA.box.left < occurrencesB.box.left) return -1;
            if (occurrencesA.box.left > occurrencesB.box.left) return 1;

            return 0; // If all criteria are equal
        });
        return entries

    })

    const missingAltTagImageMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let entries = processOccurrences('missingAltTagImageMarks', function (entry, newItem) {
            newItem.query = 'missing_alt_tag';

            newItem.priority = entry.priority || 'low';
            newItem.name = '';
            newItem.description = entry.url;

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                occurrence.highlightDetails.title = `Missing Alt Tag Detected`;
                occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
                    defaultProtocol: 'https',
                    className: 'external-link',
                    target: {
                        url: '_blank'
                    }
                });
                occurrence.highlightDetails.context = occurrence.context;
                occurrence.highlightDetails.showContext = false;

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }

        });
        entries.sort((a, b) => {
            if (!a.occurrences[0] || !b.occurrences[0]) {
                return 0;
            }
            // First, sort by 'visible' (true values first)
            let occurrencesA = a.occurrences[0];
            let occurrencesB = b.occurrences[0];

            if (!occurrencesA) {
                return -1;
            }
            if (!occurrencesB) {
                return 1;
            }

            if (occurrencesA.visible && !occurrencesB.visible) return -1;
            if (!occurrencesA.visible && occurrencesB.visible) return 1;

            if (occurrencesA.box.top < occurrencesB.box.top) return -1;
            if (occurrencesA.box.top > occurrencesB.box.top) return 1;

            if (occurrencesA.box.left < occurrencesB.box.left) return -1;
            if (occurrencesA.box.left > occurrencesB.box.left) return 1;

            return 0; // If all criteria are equal
        });
        return entries

    })

    const brokenImageMarks = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let entries = processOccurrences('brokenImageMarks', function (entry, newItem) {
            newItem.query = 'broken_image';

            newItem.priority = entry.priority || 'high';
            newItem.name = '';
            newItem.description = entry.url;

            for (let occurrence of newItem.occurrences) {
                occurrence.highlightDetails = {}

                occurrence.highlightDetails.title = `Broken Image Detected`;
                occurrence.highlightDetails.text_1 = linkifyHtml(entry.url, {
                    defaultProtocol: 'https',
                    className: 'external-link',
                    target: {
                        url: '_blank'
                    }
                });
                occurrence.highlightDetails.context = occurrence.context;
                occurrence.highlightDetails.showContext = false;

                if (occurrence.box && occurrence.box.height > 500) {
                    occurrence.box.height = 50;
                }

            }

        });
        entries.sort((a, b) => {
            if (!a.occurrences[0] || !b.occurrences[0]) {
                return 0;
            }
            // First, sort by 'visible' (true values first)
            let occurrencesA = a.occurrences[0];
            let occurrencesB = b.occurrences[0];

            if (!occurrencesA) {
                return -1;
            }
            if (!occurrencesB) {
                return 1;
            }

            if (occurrencesA.visible && !occurrencesB.visible) return -1;
            if (!occurrencesA.visible && occurrencesB.visible) return 1;

            if (occurrencesA.box.top < occurrencesB.box.top) return -1;
            if (occurrencesA.box.top > occurrencesB.box.top) return 1;

            if (occurrencesA.box.left < occurrencesB.box.left) return -1;
            if (occurrencesA.box.left > occurrencesB.box.left) return 1;

            return 0; // If all criteria are equal
        });
        return entries

    })

    const crawlErrors = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        let result = [];
        for (let deviceRender of activeDeviceRender.deviceRenders) {
            if (deviceRender.crawlErrors) {
                result = result.concat(deviceRender.crawlErrors);
            }
        }
        return result;
    })


    const cloudFlareError = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        return !!crawlErrors.value.find((error) => {
            return error.reason === 'cloudflare'
        });


    })

    let performanceScore = computed(() => {
        // force reactivity (this is so that highlightdetails will be recalculated)
        route.query

        if (activeDeviceRender.page.lighthouse && activeDeviceRender.page.lighthouse.performance_score) {
            let p = activeDeviceRender.page.lighthouse.performance_score;
            // round to integer
            return Math.round(p * 100);
        }
        return ''

    })

    function getIssueFromQuery(query) {

        let index = parseInt(query.i, 10) || 1;
        let result = {
            section: query.section,
            index: index - 1,
            entry: null,
        }
        // if (!devicePreview.value) {
        //   return result;
        // }

        if (query.section === 'ai') {
            if (index > 0) {
                return result;
            }
            return {};
        }

        if (query.spelling) {
            let spellingMark = spellingMarks.value.find((spellingMark) => {
                return spellingMark.id === query.spelling;
            })
            if (spellingMark) {
                result.entry = spellingMark;
                return result;
            }
        } else if (query.inconsistent_capitalization) {
            let spellingMark = inconsistentCapitalizationMarks.value.find((spellingMark) => {
                return spellingMark.id === query.inconsistent_capitalization;
            })
            if (spellingMark) {
                result.entry = spellingMark;
                return result;
            }
        } else if (query.layout) {
            let layoutError = layoutErrors.value.find((layoutError) => {
                return layoutError.id === query.layout;
            })
            if (layoutError) {
                result.entry = layoutError;
                return result;
            }
        } else if (query.broken_link) {
            let brokenLink = brokenLinkMarks.value.find((brokenLink) => {
                return brokenLink.id === query.broken_link;
            })
            if (brokenLink) {
                result.entry = brokenLink;
                return result;
            }
        } else if (query.content) {
            let contentIssue = contentIssueMarks.value.find((contentIssue) => {
                return contentIssue.id === query.content;
            })
            if (contentIssue) {
                result.entry = contentIssue;
                return result;
            }
        } else if (query.internal_redirect) {
            let internalRedirect = internalRedirectMarks.value.find((internalRedirect) => {
                return internalRedirect.id === query.internal_redirect;
            })
            if (internalRedirect) {
                result.entry = internalRedirect;
                return result;
            }
        } else if (query.broken_image) {
            let brokenImage = brokenImageMarks.value.find((brokenImage) => {
                return brokenImage.id === query.broken_image;
            })

            if (brokenImage) {
                result.entry = brokenImage;
                return result;
            }
        } else if (query.missing_alt_tag) {
            let missingImageAltTag = missingAltTagImageMarks.value.find((missingImageAltTag) => {
                return missingImageAltTag.id === query.missing_alt_tag;
            })

            if (missingImageAltTag) {
                result.entry = missingImageAltTag;
                return result;
            }
        } else if (query.history) {
            return result;
        } else {
            return result;
        }

        return {}
    }

    return {
        issues,
        performanceScore,
        cloudFlareError,
        consoleErrors,
        crawlErrors,
        brokenImageMarks,
        missingAltTagImageMarks,
        internalRedirectMarks,
        brokenResources,
        consoleWarnings,
        brokenLinkMarks,
        inconsistentCapitalizationMarks,
        spellingMarks,
        contentIssueMarks,
        seoErrors,
        contentErrors,
        layoutErrors,
        seoWarnings,
        getIssueFromQuery
    }
})