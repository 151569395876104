import { defineStore } from 'pinia'
import { ref, watch } from "vue";
import { gql } from "graphql-tag";
import { useQuery, useSubscription } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { EventBus } from "@/events/eventBus";
import { useHead } from "unhead";

export const useWebsiteStore = defineStore('website', () => {
    const loaded = ref(false);
    const lastUpdated = ref(null);
    const website = ref(null);
    const route = useRoute()
    const router = useRouter();

    // throttle but ensure that the last call is always executed
    function throttle(f, delay = 0, ensure = true) {
        let lastCall = Number.NEGATIVE_INFINITY;
        let wait;
        let handle;
        return (...args) => {
            wait = lastCall + delay - Date.now();
            clearTimeout(handle);
            if (wait <= 0 || ensure) {
                handle = setTimeout(() => {
                    f(...args);
                    lastCall = Date.now();
                }, wait);
            }
        };
    }

    const refetchAll = throttle(async () => {
        await refetch();
        EventBus.emit('refetchAll', null);
    }, 1100, true)

    const scanStatus = ref({
        site: {
            status: 'idle',
            in_progress: false,
            message: '',
        },
        pages: {
            status: 'completed',
            in_progress: false,
        },
        opportunities: {
            status: 'completed',
            in_progress: false,
        }
    });
    const selectWebsiteVariables = ref({
        shortuuid: "",
    })
    const SELECT_LAST_UPDATED = gql`
        subscription Websites($shortuuid: String!) {
          websites_website(where: {deleted: {_eq: false}, shortuuid: {_eq:$shortuuid}}){
            id
            shortuuid
            last_updated
          }
        }`

    const {result: lastUpdatedSubscriptionResult} = useSubscription(SELECT_LAST_UPDATED, selectWebsiteVariables, () => ({
            enabled: selectWebsiteVariables.value.shortuuid !== 0,
        })
    )
    watch(
        lastUpdatedSubscriptionResult,
        data => {
            refetchAll()
        }
    )
    const SELECT_WEBSITE = gql`
        query Websites($shortuuid: String!) {
          websites_website(where: {deleted: {_eq: false}, shortuuid: {_eq:$shortuuid}}, order_by: {last_full_audit: desc}){
            id
            shortuuid
            url
            preview_favicon
            issue_stats
            last_updated
            last_full_audit
            crawl_status
            crawl_options
            max_crawl_pages
            custom_dictionary
            meta
            keywordgaps (where: {status: {_in: ["active"]}}, order_by: {id: desc}) {
              id
              page_url
              shortuuid
              status
              keywords
              results
              last_updated  
              canonicalpage {
                  id
                  external_id
              }  
            }          
          }          
        }`

    const {result, refetch} = useQuery(SELECT_WEBSITE, selectWebsiteVariables, () => ({
            enabled: selectWebsiteVariables.value.shortuuid !== "",
            notifyOnNetworkStatusChange: true
            // fetchPolicy: 'network-only',
        })
    )
    watch(
        result,
        async data => {
            if (!data) {
                return
            }

            if (!data.websites_website.length) {
                website.value = null;
                // await router.push({name: 'accountDashboard', query: {missing: selectWebsiteVariables.value.shortuuid}})
                return;
            }

            let keywordGaps = [];
            for (let item of data.websites_website[0].keywordgaps) {
                keywordGaps.push({
                    id: item.id,
                    shortuuid: item.shortuuid,
                    page: item.page_url,
                    keywords: item.keywords,
                    pageId: (item.canonicalpage || {}).external_id || '',
                    path: item.page_url.substring(item.page_url.indexOf('/', 8)),
                    status: item.status,
                    lastUpdated: item.last_updated,
                    results: item.results
                })
            }


            loaded.value = true
            let customDictionary = {}
            Object.assign(customDictionary, data.websites_website[0].custom_dictionary || {});
            if (!customDictionary.words) {
                customDictionary['words'] = [];
            }
            let issues = {};
            Object.assign(issues, data.websites_website[0].issue_stats || {});
            if (!issues['broken_images']) {
                issues['broken_images'] = 0;
            }
            if (!issues['broken_links']) {
                issues['broken_links'] = 0;
            }
            if (!issues['inconsistent_capitalization']) {
                issues['inconsistent_capitalization'] = 0;
            }
            if (!issues['console_warnings']) {
                issues['console_warnings'] = 0;
            }
            if (!issues['console_errors']) {
                issues['console_errors'] = 0;
            }
            if (!issues['broken_resources']) {
                issues['broken_resources'] = 0;
            }
            if (!issues['internal_redirects']) {
                issues['internal_redirects'] = 0;
            }
            if (!issues['spelling']) {
                issues['spelling'] = 0;
            }
            if (!issues['missing_title']) {
                issues['missing_title'] = 0;
            }
            if (!issues['missing_description']) {
                issues['missing_description'] = 0;
            }
            if (!issues['missing_favicon']) {
                issues['missing_favicon'] = 0;
            }
            if (!issues['missing_image_alt_tag']) {
                issues['missing_image_alt_tag'] = 0;
            }
            if (!issues['performance']) {
                issues['performance'] = {
                    'slow_pages': 0,
                    'large_images': 0,
                }
            }
            if (!issues['layout']) {
                issues['layout'] = {
                    'lsep': 0,
                    'etx': 0,
                    'unsupported_unicode': 0,
                    'aspect_ratio': 0,
                    'overflow_h': 0,
                    'overflow_w': 0,
                    'outside_window': 0,
                    'narrow': 0,
                    'button_overlap': 0,
                    'text_overlap': 0,
                    'horizontal_scrolling': 0,
                }
            }
            if (!issues['score']) {
                issues['score'] = 0;
            }
            website.value = {
                'id': data.websites_website[0].id,
                'lastUpdated': data.websites_website[0].last_updated,
                'lastFullAudit': data.websites_website[0].last_full_audit,
                'shortuuid': data.websites_website[0].shortuuid,
                'url': data.websites_website[0].url,
                'previewFavicon': data.websites_website[0].preview_favicon,
                'maxCrawlPages': data.websites_website[0].max_crawl_pages,
                'customDictionary': customDictionary,
                'crawlOptions': data.websites_website[0].crawl_options || {},
                'issueStats': issues,
                'keywordGaps': keywordGaps,
                'meta': data.websites_website[0].meta || {},
            };

            lastUpdated.value = data.websites_website[0].last_updated;
            let crawlStatus = data.websites_website[0].crawl_status;
            if (!(crawlStatus && crawlStatus.site)) {
                crawlStatus = {
                    site: {
                        status: 'completed',
                        in_progress: false,
                    },
                    // pages: {
                    //     status: 'completed',
                    //     in_progress: false,
                    // },
                    opportunities: {
                        status: 'completed',
                        in_progress: false,
                    }
                }
            }
            scanStatus.value.pages = undefined;
            scanStatus.value.site.status = crawlStatus.site.status;
            scanStatus.value.site.message = crawlStatus.site.message || '';
            scanStatus.value.site.in_progress = !['completed', 'error'].includes(crawlStatus.site.status);
            // scanStatus.value.pages.status = crawlStatus.pages.status;
            // scanStatus.value.pages.in_progress = !['completed', 'error'].includes(crawlStatus.pages.status);
            if (crawlStatus.opportunities) {
                scanStatus.value.opportunities.status = crawlStatus.opportunities.status;
                scanStatus.value.opportunities.in_progress = ['pending', 'in_progress'].includes(crawlStatus.opportunities.status);
            }
            else{
                scanStatus.value.opportunities.status = 'completed';
                scanStatus.value.opportunities.in_progress = false;
            }

            const route = router.resolve({name: 'dashboard', params: {websiteId: website.value.shortuuid}})
            const absoluteURL = new URL(route.href, window.location.origin).href;

            if (website.value.meta['image']) {
                useHead({
                    meta: [
                        {
                            name: 'twitter:card',
                            content: 'summary_large_image',
                        },
                        {
                            name: 'twitter:site',
                            content: '@pagewatchdev',
                        },
                        {
                            name: 'og:url',
                            content: absoluteURL,
                        },
                        {
                            name: 'og:title',
                            content: website.value.meta['title'],
                        },
                        {
                            name: 'og:image',
                            content: website.value.meta['image'],
                        },
                    ],
                })
            }
        },
        {
            lazy: true // Don't immediately execute handler
        }
    )

    watch(() => route.params.websiteId, async (websiteId) => {
        if (!websiteId) {
            loaded.value = false;
            website.value = null;
        }
        selectWebsiteVariables.value.shortuuid = websiteId || ""
        setTimeout(() => {
            refetchAll();
        }, 5)
    }, {immediate: true});

    async function approveIssue(category, id, option = 'add') {

        return await axios.post(`websites/${website.value.shortuuid}/issue/`, {
            option: option,
            category: category,
            id: id
        })
    }


    return {loaded, lastUpdated, scanStatus, website, approveIssue}
})