<script setup>

import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  history: {type: Object, default: []}
})

const router = useRouter()
const route = useRoute()
const options = ref({
  plotOptions: {

    heatmap: {
      radius: 1,
      reverseNegativeShade: true,
    },
  },
  states: {
    active: {
      filter: {
        type: 'darken',
        value: 0.85
      },
    }
  },
  colorss: [function ({value, seriesIndex, w}) {
    if (value < 0.5) {
      return '#afa50b'
    } else {
      return '#37d97e'
    }
  }],

  theme: {
    palette: 'palette1' // upto palette10
  },
  chart: {
    id: 'vuechart-example',

    toolbar: {
      show: false
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 1,
    show: true,
    colors: ['#369ff4']
    // curve: 'straight'
  },
  tooltip: {
    enabled: true,
    intersect: true,
    shared: false,
    x: {
      show: false,
      formatter: (i, d) => {

        let date = new Date(i)
        // format date as string (12 Feb 4:34 PM)
        date = date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
        return date
      }
    },
    marker: {
      show: false
    },
    y: {
      formatter: function (v) {
        return v + ' Updates Detected<br> &nbsp; &nbsp;  (Click to view)';
      },
      title: {
        formatter: function (seriesName, d) {
          return ''
        }
      }
    }

    // fixed:{
    //   enabled: false,
    // }
  },
  annotations: {
    xaxis: []
  },
  xaxis: {
    type: 'category',
    categories: props.history.map((entry) => new Date(entry.date).getTime()),
    // tickAmount: 100,
    offsetY: -5,
    tickPlacement: 'between',
    position: 'bottom',

    labels: {
      show: true,
      rotate: 0,
      formatter: function (value) {
        let s = new Date(value).toLocaleDateString('en-us', {
          month: "short",
          day: "numeric",
        })
        return `${s}`
      },
    },

  },
  yaxis: {
    labels: {
      show: false,
    },

  },
  legend: {
    position: 'bottom',
  },

  dataLabels: {
    enabled: false
  },
})

function dataPointMouseEnter(event, x, data) {
  document.getElementById('vuechart-example').style.cursor = "pointer";
}

function dataPointMouseLeave(event, x, data) {
  document.getElementById('vuechart-example').style.cursor = "default";
}

let pointSelected = false;

function dataPointSelection(event, chartContext, selectedPoints) {
  let query = {};
  pointSelected = 1;
  query.history = props.history.length - selectedPoints.dataPointIndex;
  query.section = 'history';
  router.push({query: query});
}

const series = ref([{
  name: 'Changes',
  data: props.history.map((entry) => (entry.new + entry.text + entry.difference))
}])

watch(() => props.history, (history) => {
  if (JSON.stringify(history.map((entry) => (entry.new + entry.text + entry.difference))) === JSON.stringify(series.value[0].data)
      && JSON.stringify(history.map((entry) => new Date(entry.date).getTime())) === JSON.stringify(options.value.xaxis.categories)) {
    return
  }
  series.value = [{
    name: 'Changes',
    data: history.map((entry) => (entry.new + entry.text + entry.difference))
  }]
  chart.value.updateOptions({
    xaxis: {
      categories: history.map((entry) => new Date(entry.date).getTime())
    }
  });
}, {deep: true})

const chart = ref(null)

watch(() => route.query, (query) => {
  // clear chart selection if we navigate away from this page
  if (pointSelected && !route.query.history) {
    chart.value.updateOptions({
      chart: {
        selectedDataPoints: null
      }
    }, false, false, false);
  }
})

defineExpose({});
</script>

<template>
<div style="height:80px">
  <apexchart @dataPointMouseLeave="dataPointMouseLeave" @dataPointMouseEnter="dataPointMouseEnter"
             @dataPointSelection="dataPointSelection"
             id="vuechart-example" ref="chart" style="margin-top:-20px" width="96%" type="heatmap"
             :height="80" :options="options"
             :series="series"></apexchart>
</div>
</template>

<style scoped lang="scss">


</style>