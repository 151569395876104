<script setup>

import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import HttpStatusCodeBadge from "@/components/HttpStatusCodeBadge.vue";
import { useWebsiteStore } from "@/stores/website";
import { storeToRefs } from "pinia";
import InnerIssueSection from "@/views/preview/sections/InnerIssueSection.vue";

const activeDeviceRender = useActiveDeviceRenderStore();
const router = useRouter()
const route = useRoute()
const props = defineProps({
  issues: Object,
  id: String,
  priority: {type: String, default: "low"},
  title: String,
});
const websiteStore = useWebsiteStore();
const {website} = storeToRefs(websiteStore);

function isOpen() {
  return route.query[props.id] !== undefined;
  // for (let issue of props.issues) {
  //   if (issue.id === route.query[props.id]) {
  //     return true
  //   }
  // }
  // return false;
}

async function ignoreIssue(issue) {
  await websiteStore.approveIssue(props.id, issue.id)

}
</script>

<template>
  <div class="accordion issue-block  position-relative " v-if="issues.length > 0">
   <inner-issue-section :issues="issues" :id="id" :priority="priority" :title="title" />
  </div>
</template>

<style>

.issue-selected {
  background-color: #e9ecef;
}

.issue-body {
  font-size: 90%;

  .accordion-body {
    padding: 6px;
    padding-top: 0;
  }
}

.issue-header {
  font-weight: 500
}

.issue-block {
  margin-bottom: 8px;
}

.issue-item {
  padding: 1rem;
  margin-top: -0px;
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 2px;

}


</style>