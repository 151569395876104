<script setup>
import { useTemplateStore } from "@/stores/template";
import { defineComponent } from 'vue'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
// import from public
import '@/assets/css/videojs.css'
import StarterFooter from "@/views/starter/StarterFooter.vue";
// Main store
const store = useTemplateStore();


function onPlayerError() {
  console.log('Player error:')
}

</script>

<template>

  <div>
    <router-view></router-view>
    <!-- Hero -->
    <div id="one-vue-hero" class="bg-page-landing pb-5">

      <div class="row justify-content-center m-0">

        <div class="col-12 col-md-8 ">

          <div class="row justify-content-center">
            <div class="col col-12 col-md-12 text-center">
              <h1 class="main-h1 mt-4 mt-md-6">
                Rank Better with your Existing Content
              </h1>
            </div>
            <div class="col col-12 col-md-10 text-center">


              <p class="fs-5 fw-medium text-muted mb-4 ">
                Find the easy wins to outrank your competitors.
              </p>

              <form class="row row-cols-lg-auto g-3 justify-content-center mb-5">
                <div class="col-12">
                  <label class="visually-hidden" for="inlineFormInputGroupUsername">Enter your website</label>
                  <div class="input-group">
                    <input type="text" class="form-control" id="inlineFormInputGroupUsername"
                           placeholder="Enter your website">
                  </div>
                </div>

                <div class="col-12">
                  <router-link class="btn btn-primary" aria-current="page" :to="{name: 'auth-signup'}">Start for free
                  </router-link>
                </div>
              </form>


            </div>

            <div class="col col-10 ">

              <span class="badge bg-warning">Live Preview</span>
              <div class="background-block mt-3">


                <table class="table table-keywords">
                  <thead>
                  <tr>
                    <td>
                      <div class="pt-4 pb-1">
                        <a target="_blank" href="https://acme-on-call.onrender.com/incident-response-workflow" class="external-link"><b>/incident-response-workflow</b></a> is missing
                        out on traffic for multiple keywords.
                        <br>
                        <div class="pt-4 pb-2" style="font-size: 105%;">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="position-relative">
                    <th scope="col" width="80%">3 Keyword Gaps</th>
                    <th width="5%">
                      <div class="rotate-text"><a
                          href="https://www.airgarage.com/location/mt-vernon-parking-baltimore-md" target="_blank"><img
                          class="ps-0 ms-0 me-0" width="22"
                          src="https://t0.gstatic.com/faviconV2?client=SOCIAL&amp;type=FAVICON&amp;fallback_opts=TYPE,SIZE,URL&amp;url=https://airgarage.com&amp;size=22">
                        Your Ranking </a></div>
                    </th>
                    <th width="5%">
                      <div class="rotate-text"><a href="https://eccouncil.org/cybersecurity-exchange/incident-handling/what-is-incident-response-life-cycle/" target="_blank"><img class="ps-0 ms-0 me-2" width="22" src="https://t0.gstatic.com/faviconV2?client=SOCIAL&amp;type=FAVICON&amp;fallback_opts=TYPE,SIZE,URL&amp;url=https://www.eccouncil.org&amp;size=22"> EC-Council</a></div>
                    </th>
                    <th width="5%">
                      <div class="rotate-text"><a href="https://aspirets.com/blog/guide-to-incident-response/" target="_blank"><img class="ps-0 ms-0 me-2" width="22" src="https://t0.gstatic.com/faviconV2?client=SOCIAL&amp;type=FAVICON&amp;fallback_opts=TYPE,SIZE,URL&amp;url=https://www.aspirets.com&amp;size=22"> Aspire Technology Solutions</a></div>
                    </th>
                    <th width="5%">
                      <div class="rotate-text"><a href="https://mattermost.com/best-practices-for-improving-incident-response-workflows/" target="_blank"><img class="ps-0 ms-0 me-2" width="22" src="https://t0.gstatic.com/faviconV2?client=SOCIAL&amp;type=FAVICON&amp;fallback_opts=TYPE,SIZE,URL&amp;url=https://mattermost.com&amp;size=22"> Mattermost</a></div>
                    </th>
                    <th scope="col">
                      <div class="text-center"></div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>What is an Incident Response Workflow?</td>
                    <td><span class="ranking_bad">78</span></td>
                    <td><span class="ranking_5_below ranking_good">1</span></td>
                    <td><span class="ranking_competitor_bad">72</span></td>
                    <td><span class="ranking_5_below ranking_good">2</span></td>
                    <td nowrap="">
                      <div>
                        <router-link
                            :to="{name: 'opportunity-demo', params: {reportRoot: 'd', websiteId: 'y3aao7mt', pageId: '6', opportunityId: 'ds7wz2ue'}, query: {topic: 1}}"
                            class="btn btn-sm btn-info"><i
                            class="nav-main-link-icon fa fa-arrow-up-right-dots"></i>
                          Show Opportunities
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Incident Response Workflow</td>
                    <td><span class="ranking_bad">88</span></td>
                    <td><span class="ranking_competitor_bad">&gt;99</span></td>
                    <td><span class="ranking_good">6</span></td>
                    <td><span class="ranking_competitor_bad">44</span></td>
                    <td nowrap="">
                      <div>

                        <router-link
                            :to="{name: 'opportunity-demo', params: {reportRoot: 'd', websiteId: 'y3aao7mt', pageId: '6', opportunityId: 'wgf1blvc'}, query: {topic: 1}}"
                            class="btn btn-sm btn-info"><i
                            class="nav-main-link-icon fa fa-arrow-up-right-dots"></i>
                          Show Opportunities
                        </router-link>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table><!--v-if--></div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- END Hero -->
    <!-- END Hero After -->


    <div class="row justify-content-center m-0">
      <div class="col-sm-8 text-center">
        <div class="d-flex gap-3 flex-row text-center align-items-center pe-4">

          <div class="content mt-0 pt-0 social-proof-box">
            <div class="py-2 text-center">
              ”The PageWatch tool is incredible. It actually out-performed a manual audit that a hired large agency
              did for one of my most recent sites.”
              <div class="container text-center">
                <div class="row">
                  <div class="col">
                    <img style="width:50px;height:50px;border-radius: 50%"
                         src="https://ph-avatars.imgix.net/2723444/original?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=170&h=170&fit=crop">
                  </div>
                  <div class="col col-6 text-start  mt-2">
                    <div class="social-proof-author">Paul Hoft</div>
                    <div class="social-proof-source">Web developer</div>
                  </div>
                  <div class="col text-end mt-2">
                    <a href="https://www.producthunt.com/products/pagewatch?comment=1088080#pagewatch"
                       target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40"
                           class="styles_logo__xwGFq">
                        <g fill="none" fill-rule="evenodd">
                          <path fill="#FF6154"
                                d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20"></path>
                          <path fill="#FFF"
                                d="M22.667 20H17v-6h5.667a3 3 0 0 1 0 6m0-10H13v20h4v-6h5.667a7 7 0 1 0 0-14"></path>
                        </g>
                      </svg>
                    </a>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="content mt-0 pt-0 social-proof-box d-none">
            <div class="py-2 text-center">
              ”At Spike.sh, we benefited a ton by PageWatch giving us timely alerts about spelling mistakes, CSS and
              website issues. Highly recommended.”
              <div class="container text-center">
                <div class="row">
                  <div class="col">
                    <img style="width:50px;height:50px;border-radius: 50%"
                         src="https://pagewatch.dev/assets/img/testimonials/testimonials-3.jpg">
                  </div>
                  <div class="col col-6 text-start  mt-2">
                    <div class="social-proof-author">Kaushik Thirthappa</div>
                    <div class="social-proof-source">Spike.sh</div>
                  </div>
                  <div class="col text-end mt-2">
                    <a href="https://spike.sh/"
                       target="_blank">
                      <img src="https://ik.imagekit.io/pagewatch/vrqla7nc/favicon.png?tr=w-20">
                    </a>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row justify-content-center m-0 mt-6">
        <div class="col-9 text-center">
          <h2 class="h1 fw-black mb-4" id="pricing">
            Pricing
          </h2>
          <div class="text-muted mb-2">No contracts, cancel any time.</div>
          <div class="row justify-content-center text-start">

            <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-12 mb-4">
              <div class="card card2 h-100" style="border:1px solid rgba(128,128,128,0.51)">
                <div class="card-body">

                  <h5 class="card-title">Essential</h5>
                  <div class="text-muted">For small sites and freelancers</div>
                  <br>
                  <span class="h2">$34</span> /month

            <div class="block-content pricing-content fs-sm p-2">
              <div><span class="fa fa-check-circle text-success"></span> 1 Domain</div>
              <div><span class="fa fa-check-circle text-success"></span> Page Audits</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 25 Pages</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> Unlimited Rechecks</div>
              <div><span class="fa fa-check-circle text-success"></span> Ranking Improvements</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 15 Ranking Opportunities</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> Custom Opportunities</div>
            </div>

                  <div class="d-grid my-3">

                    <router-link :to="{name: 'auth-signup'}" class="btn btn-outline-dark btn-block">
                      <span>Start for free</span>
                    </router-link>
                  </div>

                </div>


              </div>

            </div>
            <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-12 mb-4">
              <div class="card card2 h-100" style="border:1px solid rgba(128,128,128,0.96)">
                <div class="card-body">

                  <h5 class="card-title">Business</h5>
                  <div class="text-muted">For teams and business owners</div>
                  <br>
                  <span class="h2">$99</span> /month
            <div class="block-content pricing-content fs-sm p-2">
              <div><span class="fa fa-check-circle text-success"></span> 5 Domains</div>
              <div><span class="fa fa-check-circle text-success"></span> Detailed Page Audits</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 50 Pages Per Domain</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> Unlimited Rechecks</div>
              <div><span class="fa fa-check-circle text-success"></span> Ranking Improvements</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 60 Ranking Opportunities</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> Custom Opportunities</div>
            </div>

                  <div class="d-grid my-3">

                    <router-link :to="{name: 'auth-signup'}" class="btn btn-dark btn-block">
                      <span>Start for free</span>
                    </router-link>
                  </div>

                </div>


              </div>

            </div>
            <div class="col-lg-4 col-xl-4 col-xxl-3 col-md-12 mb-4">
              <div class="card card2 h-100" style="border:1px solid rgba(128,128,128,0.51)">
                <div class="card-body">

                  <h5 class="card-title">Agency</h5>
                  <div class="text-muted">For agencies and larger teams</div>
                  <br>
                  <span class="h2">$329</span> /month
            <div class="block-content pricing-content fs-sm p-2">
              <div><span class="fa fa-check-circle text-success"></span> Unlimited Domains</div>
              <div><span class="fa fa-check-circle text-success"></span> Page Audits</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 50 Pages Per Domain</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> Unlimited Rechecks</div>
              <div><span class="fa fa-check-circle text-success"></span> Ranking Improvements</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> 250 Ranking Opportunities</div>
              <div class="ms-2 text-muted"><span class="fa fa-check text-success"></span> $1.00 Per Extra</div>
            </div>

                  <div class="d-grid my-3">

                    <router-link :to="{name: 'auth-signup'}" class="btn btn-outline-dark btn-block">
                      <span>Start for free</span>
                    </router-link>
                  </div>

                </div>


              </div>

            </div>


          </div>
        </div>
    </div>


    <starter-footer/>

  </div>
</template>
<style>
h1.main-h1 {
  font-size: 2.8em;
  font-weight: 700;
  line-height: 1.2;
  color: #333;
}


div.faq {
  margin-top: 2px;
  margin-left: 8px;
  margin-bottom: 12px;
  font-size: 90%;
}

div.plan-features {
  line-height: 26px;
}

div.card-body {
  padding-bottom: 2px;
}

div.pricing-content {
  line-height: 32px;
}

.bg-page-landingx {
  background-image: linear-gradient(to bottom, transparent 0%, rgba(188, 134, 90, 0.12) 50%, transparent 100%),
  linear-gradient(to right, transparent 0%, rgba(188, 134, 90, 0.12) 50%, transparent 100%),
  radial-gradient(circle, rgba(7, 7, 7, 0.09) 1px, transparent 0);
  background-size: cover, 20px 20px;
}

.bg-page-landing {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(220, 164, 116, 0.07) 20%, rgba(216, 161, 118, 0.07) 90%, rgba(255, 255, 255, 1) 100%),
  radial-gradient(circle, rgba(7, 7, 7, 0.09) 1px, transparent 0);
  background-size: auto, 20px 20px;
}

.vjs-control-bar {
  display: none !important;
}

.social-proof-body {
  background-color: rgba(255, 163, 40, 0.09);
  border-radius: 10px;
  font-size: 1.0em;
}

.social-proof-author {
  font-size: 0.8em;
}

.social-proof-source {
  font-size: 0.8em;
  color: #6c757d;
}

.section-color {
  background-color: #EAEEF2
}

.feature-preview .vjs-poster img, .feature-preview .vjs-tech {
  border-radius: 20px;
  background-color: rgba(255, 163, 40, 0.09);
}

.feature-preview .video-js {
  border-radius: 20px;
  background-color: rgba(255, 163, 40, 0.09);
}

.social-proof-box {
  max-width: 680px;
  border: 0px solid rgba(128, 128, 128, 0.26);
  border-radius: 8px;
  color: #676768;
  font-size: 11pt;
}

/* hide videojs errors */
.vjs-error-display {
  display: none !important;
}

.vjs-poster {
  background-color: white !important;
}

.table-keywords {
}

.table-keywords td {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  vertical-align: top;
}

.rotate-text {
  max-width: 194px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 20px;
  transform: rotate(-35deg);
  transform-origin: top left;
  white-space: nowrap;
  margin-top: -16px; /* Adjust this value as needed */
}

.ranking_5_below {
  color: #28a745;
  font-weight: bold;
}

.ranking_good {
  color: #28a745;
}

.ranking_competitor_bad {
  color: #dc8935;
}

.ranking_bad {
  color: #dc8935;
  font-weight: bold;
}

.route-tab {
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
}
</style>
