<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { EventBus } from '@/events/eventBus';

const notificationCredits = ref(null);
const notificationLogin = ref(null);
onMounted(() => {
      notificationCredits.value = new window.bootstrap.Toast(
          document.getElementById("notification-credits")
      );
      notificationLogin.value = new window.bootstrap.Toast(
          document.getElementById("notification-login")
      );
      EventBus.on('showNotification', showNotification);
    }
)

onUnmounted(() => {
  EventBus.off('showNotification', showNotification);
});
const notificationPlanMessage = ref('Update Your Subscription');
const notificationTitle = ref('Error');
const notificationType = ref('plan');
const notificationLoginMessage = ref('Login Required');
function showNotification(notification) {
  console.log(notification)
  notificationType.value = notification.type;
  if (notification.type === 'plan'){
    notificationTitle.value = notification.title || 'Update Your Subscription';
    notificationPlanMessage.value = notification.message || 'Update Your Subscription';
    notificationCredits.value.show();
  }
  if (notification.type === 'error'){
    notificationTitle.value = notification.title || 'Error';
    notificationPlanMessage.value = notification.message || 'Error';
    notificationCredits.value.show();
  }

  if (notification.type === 'login') {
    notificationLoginMessage.value = notification.message || 'Login Required';
    notificationLogin.value.show();
  }

}

defineExpose({
  showNotification,
});
</script>
<template>
  <div id="notification-credits" data-bs-delay="8000" class="toast align-items-center text-bg-danger border-0"
       role="alert"
       aria-live="assertive"
       aria-atomic="true">
    <div class="toast-header">
      <i class="fa fa-warning text-danger me-2"></i>
      <strong class="me-auto">{{ notificationTitle }}</strong>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
      ></button>
    </div>
    <div class="d-flex">

      <div class="toast-body">
        {{ notificationPlanMessage }}<br>
        <router-link v-if="notificationType === 'plan'" @click="notificationCredits.hide()" :to="{name: 'subscription'}"
                     class="btn btn-sm btn-primary mt-2">Change Plan
        </router-link>
      </div>

    </div>
  </div>
  <div id="notification-login" data-bs-delay="8000" class="toast align-items-center text-bg-danger border-0"
       role="alert"
       aria-live="assertive"
       aria-atomic="true">
    <div class="toast-header">
      <i class="fa fa-warning text-danger me-2"></i>
      <strong class="me-auto">Login Required</strong>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
      ></button>
    </div>
    <div class="d-flex">

      <div class="toast-body">
        {{ notificationLoginMessage }}<br>
        <router-link @click="notificationLogin.hide()" :to="{name: 'auth-login'}"
                     class="btn btn-sm btn-primary mt-2">Log In
        </router-link>
      </div>

    </div>
  </div>
</template>

<style>

@media only screen and (min-width: 768px) {
}

@media only screen and (max-width: 1199px) and  (min-width: 768px) {

}


</style>