<script setup>

import { computed, ref } from "vue";
import DevicePreview from "@/components/DevicePreview.vue";
import { onMounted, onUnmounted } from "vue";
const props = defineProps({
  keywordDensity: {type: Object, default: []},
  stats: {type: Object, default: {}},
})

const headingKeywords = computed(() => {
  let result = props.keywordDensity.filter(keyword => keyword.main.reduce((a, b) => a + b, 0) > 1)
  return result.slice(0, 25).map(keyword => {

    // find the second largest number

    // sort the array in descending order, skipping the first element and then remove the first (largest) element
    let min = [...keyword.main.slice(1)].sort().reverse().slice(1)
    if (min.length > 1 && min[1] > 0) {
      min = min[1]
    } else {
      min = min[0]
    }

    let max = Math.max(...keyword.main.slice(1))
    if (max === min){
      max = max + 1
    }

    let progressMin = Math.max(0, Math.min(keyword.main[0], min) - 1)
    let progressMax = Math.max(keyword.main[0], max) + 1

    let range =  progressMax - progressMin
    let startPercent = Math.floor(((min - progressMin) / range) * 100)
    let ownPercent = Math.max(5, Math.floor(((keyword.main[0] - progressMin) / range) * 100))
    let endPercent = 100 - Math.floor(((progressMax - max) / range) * 100)

    if (ownPercent > 5 && ownPercent < endPercent){
      ownPercent += 10;
    }

    return {
      keyword: keyword.keyword,
      own: keyword.main[0],
      min: min,
      startPercent: startPercent,
      ownPercent: ownPercent,
      endPercent: endPercent,
       ownColor: ownPercent < startPercent ? 'red' : '#54c354',
      color: ownPercent >= startPercent ? 'green' : 'orange',
      range: range,
      max: Math.max(...keyword.main.slice(1)),
    }
  })
})

const contentStats = ((entry) => {


  let max = entry.competitors.max

  let min = entry.competitors.min
  let progressMin = Math.min(entry.own, min)
  let progressMax = Math.max(entry.own, max)

  let range = progressMax - progressMin
  let startPercent = Math.floor(((min - progressMin) / range) * 100)
  let ownPercent = Math.max(5, Math.floor(((entry.own - progressMin) / range) * 100))
  let endPercent = 100 - Math.floor(((progressMax - max) / range) * 100)


  return {
    own: entry.own,
    min: min,
    startPercent: startPercent,
    ownPercent: ownPercent,
    ownColor: ownPercent < startPercent ? 'black' : '#54c354',
    color: ownPercent >= startPercent ? 'green' : 'red',
    endPercent: endPercent,
    range: range,
    max: max,
  }
})

// Helper variables
let popoverTriggerList = [];
let popoverList = [];

// Init popovers on content loaded
onMounted(() => {
  // Grab all popover containers..
  popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );

  // ..and init them
  popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new window.bootstrap.Popover(popoverTriggerEl, {
      container: popoverTriggerEl.dataset.bsContainer || "#page-container",
      animation:
        popoverTriggerEl.dataset.bsAnimation &&
        popoverTriggerEl.dataset.bsAnimation.toLowerCase() == "true"
          ? true
          : false,
      trigger: popoverTriggerEl.dataset.bsTrigger || "hover focus",
    });
  });
});

// Dispose popovers on unMounted
onUnmounted(() => {
  popoverList.forEach((popover) => popover.dispose());
});

</script>

<template>


    <div class="pt-0 mt-0 max-height-overflow ms-3" style="max-height: 220px">

      <div class="row text-start justify-content-start p-1 pe-0 w-100" v-for="keyword in headingKeywords">
        <div class="col ">
          <small> {{ keyword.keyword }}</small>

        </div>
        <div class="col col-auto">
          <div class="progress-wrapper">
            <span class="progress-label">{{ keyword.own }}/{{ keyword.min }}-{{ keyword.max }}</span>
          </div>
        </div>
      </div>

    </div>

</template>

<style>
.progress-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4px; /* Adds space below if needed */
}

.custom-progress {
  flex-grow: 1;
  position: relative;
  height: 10px; /* Adjust height as needed */
  background-color: #c9cbcf; /* Gray background */
  margin: 0 10px; /* Space between the progress bar and the labels */
  overflow: visible;
}

.progress-bar-range {
  position: absolute;
  left: calc(var(--min) * 1%);
  width: calc((var(--max) - var(--min)) * 1%);
  background-color: #007bff; /* Adjust color of the progress part */
  height: 100%;
}

.progress-bar-range2 {
  position: absolute;
  left: calc(var(--min) * 1%);
  top: -2px;
  width: 2px;
  background-color: #007bff; /* Adjust color of the progress part */
  height: 14px;
}

.progress-label {
  font-size: 14px; /* Adjust font size as needed */
  color: #878787; /* Adjust text color as needed */
  white-space: nowrap;
  margin-left: -4px; /* Space between max label and bar */
  font-size: 12px;
}


</style>