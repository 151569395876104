<script setup>
import { gql } from "graphql-tag";
import VueMarkdown from 'vue-markdown-render'
import { onMounted, onUnmounted, ref, watch, computed } from "vue";
import { useSubscription } from "@vue/apollo-composable";
import { useActiveDeviceRenderStore } from "@/stores/activeDeviceRender";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute()

const props = defineProps({
  devicePreview: Object,
  feedbackCategory: {
    type: String,
    default: "design_feedback",
  },
});


const activeDeviceRender = useActiveDeviceRenderStore();

const annotations = ref([])
const categories = ref([])
const status = ref("new")
const goalCategory = ref("design_feedback")
const firstImpressionsResult = ref("")
const SELECT_CHAT = gql`
        subscription Chats($deviceRenderId: bigint!, $feedbackCategory: String!) {
          ai_chat(where: {device_render_id: {_eq: $deviceRenderId}, category: {_eq: $feedbackCategory}}, order_by: {created_at: desc}, limit: 1) {
            id
            created_at
            status
            message
            annotations
            processed_message
            processed_data
          }
        }`
const selectChatVariables = computed(() => ({
  deviceRenderId: activeDeviceRender.deviceRender.id,
  feedbackCategory: props.feedbackCategory,
}));
const {result} = useSubscription(SELECT_CHAT, selectChatVariables, () => ({
      enabled: selectChatVariables.value.deviceRenderId !== 0,
    })
)

let feedbackGenerated = false;
let feedbackAudience = ref('general')

watch(
    result,
    data => {
      if (data.ai_chat.length === 0) {
        status.value = "new"
        firstImpressionsResult.value = ""
        annotations.value = []
        categories.value = []
        return
      }
feedbackGenerated = true
      status.value = data.ai_chat[0].status;
      firstImpressionsResult.value = data.ai_chat[0].processed_message
      annotations.value = data.ai_chat[0].annotations && data.ai_chat[0].annotations['annotations'] || []

      categories.value = []
      let i = 0;
      let oldLabel = '';
      if (data.ai_chat[0].processed_data['result']) {
        for (let category of data.ai_chat[0].processed_data['result']['categories']) {
          let sections = [];
          for (let section of category['sections']) {
            sections.push({
              'id': i,
              'title': section.title,
              'content': section.content,
              'completed': true,
            })
            i += 1;
          }

          categories.value.push({
            'name': category['name'],
            'sections': sections,
          })
        }
        if (categories.value.length > 0) {
          if (categories.value[categories.value.length - 1]['sections'].length > 0) {
            categories.value[categories.value.length - 1]['sections'][categories.value[categories.value.length - 1]['sections'].length - 1]['completed'] = status.value === 'completed';
          }
        }
      }

      showBoxes(false)
      setTimeout(() => {
        highlightSelectedFeedback(false)
      }, 0)

    },
    {
      lazy: true // Don't immediately execute handler
    }
)

watch(status, (newVal, oldVal) => {
  if (newVal === 'new') {
  }
}, {
  immediate: true
})

watch(
    () => props.devicePreview.mouseOverIndex,
    (newVal, oldVal) => {
      let feedbackElement = document.getElementById('feedback');
      if (feedbackElement) {
        let aItems = feedbackElement.querySelectorAll('a');

        // Loop through each element and remove the class
        aItems.forEach(function (a) {
          a.parentElement.classList.remove('hover'); // Replace 'your-class-name' with the class you want to remove
        });

        let activeElement = feedbackElement.querySelector(`a[href="?section=${route.query.section}&i=${props.devicePreview.mouseOverIndex}"]`);
        if (activeElement) {
          activeElement.parentElement.classList.add('hover')
          // activeElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
      }
    },
    {
      immediate: true
    });

function showBoxes(scroll = true) {

  let boxes = []
  let query = {}
  let count = 0;
  let index = 0;
  let ratio = props.devicePreview.ratio();

  for (let annotation of annotations.value) {
    count += 1;
    index += 1;
    let q = {}
    Object.assign(q, query);
    q['i'] = index;
    q['scroll'] = '1';
    q['section'] = route.query.section;
    let id = `highlight-box-inactive-${count}`
    // if (activeIssue && activeIssue.id === issue.id) {
    id = `highlight-box-ai-${count}`
    // }
    boxes.push({
      'id': id,
      'key': id,
      'i': `${index}`,
      'box': annotation.box,
      'context': 'dffd',
      'highlight': false,
      'level': '',
      'active': route.query.i === `${index}`,
      'visible': true,
      'query': q,
      'style': {
        'visibility': annotation.default ? 'hidden' : 'visible',
        'left': `${annotation.box.left * ratio}px`,
        'top': `${annotation.box.top * ratio}px`,
        'width': `${annotation.box.width * ratio}px`,
        'height': `${annotation.box.height * ratio}px`,
      },
      'variant': {
        'type': 'rough',
        'style': annotation.default ? 'circle' : 'box',
        'sentiment': annotation.sentiment,
        'color': annotation.sentiment === 'positive' ? 'green' : 'red',
      }
    })
  }
  props.devicePreview.showBoxes(boxes)
  if (scroll) {
    if (!route.query.scroll && boxes.length >= parseInt(route.query.i, 10)) {
      props.devicePreview.scrollToBox(boxes[parseInt(route.query.i, 10) - 1].box)
    }
  }
}


async function generateFeedback() {
  feedbackGenerated = true;
  aiProgress.value = 5.0
  let r = await axios.post(`/ai/feedback/`, {
    device_render_id: activeDeviceRender.deviceRender.id,
    category: 'design_feedback',
    options: {
      'audience': feedbackAudience.value
  }
  })
  if (!r.data.success) {
    status.value = 'failed'
  }
}

function scrollToIssue(s) {
  let href = '';
  if (s.target && s.target.tagName === 'A') {
    href = s.target.href;
  } else if (s.target && s.target.children.length > 0) {
    href = s.target.children[0].href;
    if (!href && s.target.children[0].children.length > 0) {  // the link might nested below a <strong> tag
      href = s.target.children[0].children[0].href;
    }
  } else {
    let sectionId = s.target.getAttribute('data-id')
    router.push({query: {'section': route.query.section, 'i': sectionId}, force: true});
    return
  }
  let url = new URL(href, window.location.href); // Creating a full URL object
  router.push({query: {'section': route.query.section, 'i': url.searchParams.get('i')}, force: true});
}

function mouseOver(s) {
  let i = -1;
  if (s.target && s.target.tagName === 'A') {
    let href = s.target.href;
    let url = new URL(href, window.location.href); // Creating a full URL object
    i = parseInt(url.searchParams.get('i'), 10) - 1;
  }
  props.devicePreview.highlightBox(i)
}

function highlightSelectedFeedback(scroll = true) {
  let feedbackElement = document.getElementById('feedback');
  if (feedbackElement) {
    let aItems = feedbackElement.querySelectorAll('li');

    // Loop through each element and remove the class
    aItems.forEach(function (li) {
      li.classList.remove('selected');
    });

    let activeElement = feedbackElement.querySelector(`a[href="?section=${route.query.section}&i=${route.query.i}"]`);
    if (activeElement) {
      let liElement = activeElement.parentElement;
      if (liElement.nodeName !== 'LI') {
        liElement = liElement.parentElement;
      }
      liElement.classList.add('selected')
      if (scroll) {
        setTimeout(() => {
          liElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }, 1);
      }
    } else {
      let activeElement = feedbackElement.querySelector(`li[data-id="${route.query.i}"]`);
      if (activeElement) {
        activeElement.classList.add('selected')
      }
    }
  }
}

watch(() => route.query, (query) => {
  if (['ai', 'insights'].includes(query.section)) {
    showBoxes()
    highlightSelectedFeedback()
  }
})

const aiProgress = ref(5.0)

function incrementProgress() {

  if (status.value === 'completed') {
    aiProgress.value = 100;
  }

  if (aiProgress.value < 100) {
    if (activeDeviceRender.devicePreview.screenshotFull.success) {
      aiProgress.value += (100 - aiProgress.value) * 0.01
    }
    // aiProgress.value += 60 / 100
    setTimeout(() => {
      incrementProgress()
    }, 100)
  }

}

let intervalId = null;
// update on interval

//clear interval on unmounted


onMounted(() => {
  showBoxes();
  incrementProgress();
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});

</script>

<template>
  <div class="background-block p-2 mt-4 mb-2">
    <small class="text-muted pe-3">
      Insights are powered by AI.
      <router-link :to="{name: 'pageInsights'}">Learn more</router-link>
    </small>

  </div>

  <template v-if="!feedbackGenerated">
    <span class="badge text-bg-warning mb-0 mt-2">Design Feedback</span>
  <div class="p-2 background-block text-muted mt-2"
       style="font-size: 0.875em" v-if="goalCategory === 'design_feedback'">


    <form class="row g-3">
      <div class="col-12 ">
        <div class="notification-block-basic mb-2">
          For this check we visually inspect your page to find conversion barriers, design issues and more.
        </div>
        <label for="inputAddress" class="form-label">Who Are You Targeting?</label>
        <select v-model="feedbackAudience" class="form-select form-select-sm" aria-label="Default select example">
          <option selected value="general">General Audience</option>
          <option value="technical">Technical Users</option>
        </select>
      </div>
      <div class="col-12 ">
        <button type="submit" class="btn btn-sm btn-outline-secondary" @click.prevent="generateFeedback">Generate Feedback</button>
      </div>
    </form>
  </div>
  <div class="p-2 background-block text-muted mt-2"
       style="font-size: 0.875em" v-if="goalCategory === 'proofread'">


    <form class="row g-3">
      <div class="col-12 ">
        <div class="notification-block-basic mb-2">
          For this check we look at your main content to find any grammar, consistency or stylistic issues.
        </div>
        <label for="inputAddress" class="form-label">What writing style is the page?</label>
        <select class="form-select form-select-sm" aria-label="Default select example">
          <option selected>General Audience</option>
          <option value="1">Technical Users</option>
        </select>
      </div>
      <div class="col-12 ">
        <button type="submit" class="btn btn-sm btn-outline-secondary" @click.prevent="generateFeedback">Proofread Page</button>
      </div>
    </form>

  </div>
  </template>

  <template v-if="feedbackGenerated">
    <div v-if="['failed'].includes(status)" class="p-2 background-block text-muted pb-2"
         style="font-size: 0.875em">
      <div class="text-danger p-1">We had some trouble generating insights.
        This could be due to a temporary issue, please try again later.
        <div class="mt-1">
          <button class="btn btn-sm btn-warning" @click.prevent="generateFeedback">Retry</button>
        </div>
      </div>
    </div>

    <div v-else-if="['new'].includes(status) || categories.length === 0" class="p-2 background-block text-muted pb-0"
         style="font-size: 0.875em">
      <div v-if="activeDeviceRender.devicePreview.screenshotFull.success">Inspecting page, please wait..</div>
      <div v-else>Waiting for page to finish loading..</div>
      <div class="progress push mt-2" style="height: 10px" role="progressbar">
        <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-dark"
            :style="{width: aiProgress + '%'}"
        ></div>
      </div>
      <div v-if="aiProgress > 99.3 && status === 'new'" class="text-danger p-1">Insights are taking longer than
        expected, <a href="">click here to retry</a>.
      </div>
    </div>
  </template>
  <div id="accordion-issue-list">
    <div class="accordion issue-block position-relative ai-feedback" v-for="category in categories">
      <span class="badge text-bg-warning mb-0 mt-2">{{ category.name }}</span>
      <template v-for="section in category.sections">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button style="font-size:14px;padding:12px" class="accordion-button issue-header position-relative"
                    type="button"
                    :class="{'collapsed': section.id > 0}"
                    data-bs-toggle="collapse" :data-bs-target="`#issue-section-${section.id}`">
              {{ section.title }}
              <span v-if="!section.completed" class="spinner-border text-primary ms-2 me-2 text-muted position-absolute"
                    style="font-size:8px;height:18px;width:18px;right:32px">      </span>
            </button>

          </h2>
          <div :id="`issue-section-${section.id}`" class="accordion-collapse collapse issue-body"
               :class="{'show': section.id === 0}"
               data-bs-parent="#accordion-issue-list">
            <div class="accordion-body">
              <ul class="list-group list-group-flush">
                <div class="list-group-item rounded">
                  <div class="ai-section" v-html="section.content" id="feedback" @click.prevent="scrollToIssue"
                       @mouseover="mouseOver"></div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style>


</style>